import { useCallback, useReducer } from 'react';
import { v4 as uuid } from 'uuid';
import { IdleProvider } from '../idle-warning/provider';
import { ModalContext } from './context';
import { MODAL_ACTIONS } from './enums/modal-actions';
import { modalReducer } from './reducer';

const { SHOW, HIDE, REMOVE, CLEAR } = MODAL_ACTIONS;

export const ModalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(modalReducer, {});

  /**
   * Hide a modal
   */
  const hide = useCallback(id => {
    dispatch({ type: HIDE, payload: { id } });
  }, []);

  /**
   * Remove a modal from state
   */
  const remove = useCallback(id => {
    dispatch({ type: REMOVE, payload: { id } });
  }, []);

  /**
   * Clear all modals in state
   */
  const clear = useCallback(() => {
    dispatch({ type: CLEAR });
  }, []);

  /**
   * Show/open new modal
   */
  const show = useCallback(
    (component, props) => {
      const id = props?.id || uuid();

      dispatch({
        type: SHOW,
        payload: { id, component, props },
      });

      return {
        id,
        hide: () => hide(id),
        remove: () => remove(id),
      };
    },
    [hide, remove]
  );

  /**
   * Show open status for a given modal id
   */
  const isOpen = useCallback(
    id => {
      return state[id]?.props.open;
    },
    [state]
  );

  const renderState = useCallback(() => {
    return Object.entries(state).map(([key, value]) => {
      const { component: Modal, props } = value;
      return (
        <Modal
          {...props}
          key={key}
          hide={() => {
            if (props.hide) {
              props.hide();
            }
            hide(key);
          }}
          remove={() => {
            if (props.remove) {
              props.remove();
            }
            hide(key);
            setTimeout(() => remove(key), 2000);
          }}
          clear={() => {
            if (props.clear) {
              props.clear();
            }
            hide(key);
            setTimeout(() => clear(), 2000);
          }}
        />
      );
    });
  }, [clear, hide, remove, state]);

  return (
    <ModalContext.Provider value={{ state, show, hide, remove, clear, isOpen }}>
      <IdleProvider>
        {children}
        {renderState()}
      </IdleProvider>
    </ModalContext.Provider>
  );
};
