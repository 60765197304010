import { CLIENT_SECRETS } from '@/config/client_secrets';
import { useBroadcastChannel, useModal } from '@/hooks';
import { useRouter } from 'next/router';
import { IdleTimerProvider } from 'react-idle-timer';
import { IdleModal } from './idle-modal';

export const IdleProvider = ({ children }) => {
  const modal = useModal();
  const router = useRouter();
  const channel = useBroadcastChannel('isIdleModalOpen');

  const timeoutMs = CLIENT_SECRETS.IDLE_MODAL_TIMEOUT_MINUTES * 60 * 1000;

  const onIdle = () => {
    const idleModalId = 'bde703e2-bffa-4d3-aada-2606f0d9ea9c';
    if (router.pathname !== '/' && !modal.isOpen(idleModalId)) {
      modal.show(IdleModal, { id: idleModalId });
      channel.postMessage('true');
    }
  };

  return (
    <IdleTimerProvider crossTab timeout={timeoutMs} onIdle={onIdle}>
      {children}
    </IdleTimerProvider>
  );
};
