import { CLIENT_SECRETS } from '@/config/client_secrets';
import { ButtonPrimary, ButtonCreate } from '@/components/button';
import { Modal } from '@/components/modal';
import { useBroadcastChannel, useInterval, useSocket } from '@/hooks';
import FrontendHttpClient from '@/utils/http-handler/frontend-http-client';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import { useIdleTimerContext } from 'react-idle-timer';
import styles from './styles.module.scss';
import NotificationService from '../notification-service';

/**
 * @param {object} params
 * @param {boolean} params.open Indicates if modal is open
 * @param {() => void} params.hide Hides modal
 */
export const IdleModal = ({ open, hide }) => {
  const router = useRouter();
  const { reset } = useIdleTimerContext();
  const [idleTime, setIdleTime] = useState(null);
  const { reconnectSocket } = useSocket();

  const { IDLE_MODAL_LOGOUT_MINUTES } = CLIENT_SECRETS;

  const channel = useBroadcastChannel('isIdleModalOpen', event => {
    if (event.data === 'false') {
      reset();
      hide();
    }
  });

  const handleReject = useCallback(async () => {
    try {
      await FrontendHttpClient.post('/api/logout');
      window.location.href = '/';
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error signing out: ', error);
    }
    reset();
  }, [reset]);

  const handleConfirm = useCallback(async () => {
    try {
      await FrontendHttpClient.post('/api/refresh-access-token', {});
    } catch (error) {
      // eslint-disable-next-line no-console
      NotificationService.info(
        'Your session has expired. Please sign in again.'
      );
      await handleReject();
      return;
    }

    reconnectSocket();
    channel.postMessage('false');
    reset();
    hide();
    setIdleTime(null);
  }, [channel, hide, reconnectSocket, reset, handleReject]);

  useEffect(() => {
    if (open) {
      setIdleTime(new Date());
      return;
    }

    setIdleTime(null);
  }, [open]);

  useEffect(() => {
    if (router.pathname === '/') {
      setIdleTime(null);
    }
  }, [router.pathname]);

  useInterval(() => {
    if (!idleTime) {
      return;
    }

    const currentDifferenceInMinutes = differenceInMinutes(
      new Date(),
      idleTime
    );

    if (currentDifferenceInMinutes >= IDLE_MODAL_LOGOUT_MINUTES) {
      handleReject();
    }
  }, 1000);

  const renderBody = () => {
    return (
      <>
        <div className={styles.idleModalButtons}>
          <ButtonCreate onClick={handleConfirm}>STILL HERE</ButtonCreate>
          <ButtonPrimary onClick={handleReject}>SIGN ME OUT</ButtonPrimary>
        </div>
      </>
    );
  };

  return (
    <Modal
      closeButton={false}
      title="Are you still there?"
      size="sm"
      show={open}
      renderBody={renderBody}
    />
  );
};
