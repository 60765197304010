import { MODAL_ACTIONS } from './enums/modal-actions';

const { SHOW, HIDE, REMOVE, CLEAR } = MODAL_ACTIONS;

/**
 * @param {ModalState} state
 * @param {{ type: string, payload: any }} action
 */
export const modalReducer = (state, action) => {
  switch (action.type) {
    case SHOW: {
      const { id, component, props } = action.payload;

      return {
        ...state,
        [id]: {
          component,
          props: { ...props, open: true },
        },
      };
    }

    case HIDE: {
      const { id } = action.payload;

      if (!state[id]) {
        return state;
      }

      return {
        ...state,
        [id]: {
          ...state[id],
          props: {
            ...state[id].props,
            open: false,
          },
        },
      };
    }

    case REMOVE: {
      const { id } = action.payload;

      if (!state[id]) {
        return state;
      }

      const newState = { ...state };
      delete newState[id];

      return newState;
    }

    case CLEAR: {
      return {};
    }

    default:
      throw new Error('Incorrect action type given to modal reducer');
  }
};
